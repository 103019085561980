.modal-container-add-new-machine {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 1;
  overflow: auto;
}

.main-container-pop-add-new-user {
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px rgb(0 0 0 / 10%);
  width: auto;
  max-height: 80vh;
  margin: 20px auto;
  overflow-y: auto;
}

.pop-up-content-add-new-user {
  width: 100%;
}

.title-add-new-user {
  font-weight: 500;
  font-size: 32px;
}
