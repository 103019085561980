.left-sidebar {
  height: 100vh;
  width: 170px;
  /* other styles */
}

.selected {
  background-color: blue;
  color: white;
}

.sidebar-options .sidebar-option {
  background-color: blue;
  color: white;
  /* other styles */
}

.digibasic-wrapper {
  position: "fixed";
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
  background-color: "#000000";
  opacity: 0.5;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  z-index: "9999";
}

.digi-stability-loading-text {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 3px #000000, 0 0 5px #000000;
  color: white;
  z-index: 999;
  animation: blink 2.5s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.digi-stability-loading-spinner {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.digi-stability-refresh-this-session-button {
  border: 1px solid gainsboro;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  height: 40px;
  color: white;
  font-size: 10px;
  background-color: #fdba45;
  box-shadow: inset -5px -5px 10px rgba(0, 0, 0, 0.25),
    inset 5px 5px 10px rgba(255, 255, 255, 0.45);
  border-radius: 4px;
}

@media (max-width: 994px) {
  .digi-stability-refresh-this-session-button {
    width: 140px;
  }
}
