.header-border-predictive-maintenance {
  width: 75% !important;
  border-bottom: solid 1px grey;
  height: 7em;
  margin: 120px auto 0 auto;
  padding-bottom: 10px;
}

.home-predictive-maintenance {
  padding-bottom: 120px;
}

.machine-list-predictive-maintenance {
  height: fit-content;
  gap: 23px;
  margin: 2em auto;
  padding: 1em;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 0px #0000005e;
  border-radius: 10px;
}

.custom-container-predictive-maintenance {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  height: fit-content;
  width: 1200px;
  margin: 2em auto;
  margin-bottom: 6em;
  padding: 1em;
  background-color: #eaeaea;
  box-shadow: 0px 0px 5px 0px #0000005e;
  border-radius: 10px;
}
.add-new-machine-predictive-maintenance {
  gap: 23px;
  margin: 2em auto;
  padding: 1em;
  background-color: #848484;
  box-shadow: 0px 0px 5px 0px #0000005e;
  border-radius: 10px;
  cursor: pointer;
  width: 22em;
  height: 9.5em;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 20px;
}

.column {
  background: #f0f0f0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.card {
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  user-select: none;
}

.card:hover {
  background-color: #f9f9f9;
}
