.register-input {
  border-color: #2250fc;
  border-width: 1.5px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .register-wrapper {
    flex-direction: column;
  }
}

.register-header {
  display: flex;
  justify-content: flex-start;
  font-family: "Poppins", sans-serif;
  margin-bottom: 50px;
  text-align: center;
}

.register-input-title {
  color: #1d1e21;
  font-size: 14px;
  font-weight: normal;
}

.register-button {
  margin-right: 10;
  border-radius: 12px;
  background: #2250fc !important;
  color: white !important;
}

@media (max-width: 414px) {
  .register-wrapper {
    display: flex;
    flex-direction: column;
  }
  .input-field-wrapper {
    margin-left: 0px !important;
  }
  .form-control {
    width: 303px !important;
  }
}
