@import url("https://fonts.googleapis.com/css2?family=Andika:wght@400;700&family=Cabin&family=Josefin+Sans&family=Lobster&family=M+PLUS+Rounded+1c&family=Poppins&family=Space+Grotesk&display=swap");

.notify-content {
  color: #000 !important;
  min-width: 400px;
  height: 140px;
  padding: 15px 25px !important;
  background: #fff !important;
  border-radius: 18px;
  overflow: auto;
  border-collapse: collapse;
  font-family: "Poppins", sans-serif;
  box-shadow: 0 0 50px rgba(0, 0, 0, 1);
  margin: 0;
}

.close {
  font-size: 36px;
  font-weight: 500;
  color: #2250fc;
}

.notify-btn {
  color: #fff;
  font-family: "Poppins", sans-serif;
  background-color: #2250fc;
  border-radius: 14px;
  height: 42px;
  font-weight: 400 !important;
}

.notify-input {
  border: 2px solid #2250fc;
  height: 42px !important;
  border-radius: 14px;
  padding-left: 20px !important;
}

.notify-input::placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  font-style: bold;
  padding-left: 0px !important;
}

.notify-header {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
