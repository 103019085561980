.modal-container-upload-data {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 1;
}

.main-container-pop-upload-data {
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 3px 3px 3px 3px rgb(0 0 0 / 10%);
}

.pop-up-content-upload-data {
  width: 100%;
}

.input-wrapper-upload-data {
  padding: 5px;
  gap: 10px;
}

.input-wrapper-upload-data input {
  width: 280px;
}
.form-control-size-upload-data {
  width: 280px;
}

.title-upload-data {
  font-weight: 500;
  font-size: 32px;
}

.create-btn-upload-data {
  display: block;
  width: 100px;
  font-size: 16px;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
}

.wrapper-upload-data {
  display: flex;
}

.exit-btn {
  border-radius: 50%;
  border: none;
  font-weight: 700;
  font-size: 20px;
  background-color: transparent;
}
.btn-close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 26px;
}

.fs-xl {
  font-size: x-large;
}
