.select-modes-container {
    position: fixed;
    z-index: 9999;
    left: 40%;
    width: 43em;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 5%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .select-modes-table tr td {
    border: 1px solid;
  }
  
  .table-second-line {
    background-color: #cdd4e9;
  }
  
  .table-first-line {
    background-color: #4471c4;
  }