@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Saira&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  background-color: #f6f7fc;
}

.prfiles-page {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.prfiles-page2 {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
}
.prfiles-page3 {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.edit-status-icon {
  margin-right: 13px;
  font-size: 20px;
  color: grey;
}

.view-icon {
  margin-right: 13px;
  font-size: 20px;
  color: #5089c6;
}
.delete-icon {
  font-size: 18px;
  color: #ff5c58;
}
.page-details {
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sending-button {
  border-radius: 5px;
  background-color: #242526;
  color: white;
  width: 150px;
  font-family: "Poppins";
}

.sending-button:hover {
  color: rgb(122, 156, 232);
}

.email-input {
  padding-left: 10px;
  width: 60%;
  margin-left: 80px;
}

.parag {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  margin-bottom: 15px;
  text-align: center;
  padding: 10px;
}

.information {
  background-color: #ffffff;
}
.information-tittle {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  margin-left: 30px;
  margin-top: 50px;
}

.list-dashboard {
  font-family: "Montserrat", sans-serif;
  list-style: none;
  margin-top: 20px;
  padding-bottom: 10px;
}

.list {
  padding-bottom: 25px;
  font-weight: bold;
}

.icon {
  color: green;
  font-size: 16px;
}

.header-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

.graph {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
}

.login-button {
  background-color: #242526;
  color: white;
  font-family: "Poppins", sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 50px;
  padding-right: 50px;
}
.login-header {
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 50px;
  color: #545455;
  text-align: center;
}
.login-name {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: #545455;
}

.login-logo {
  width: 150px;
  margin-left: 20px;
}

html {
  background-color: #f6f7fc;
}

.subscription-logo {
  font-size: 20px;
  background-color: #242526;
  font-family: "Montserrat", sans-serif;
  padding: 6px;
  color: white;
  width: 35%;
  margin-left: 130px;
  border-radius: 5px;
}

.received-page {
  margin-top: 140px;
}
.received-logo {
  width: 60%;
  margin-left: 50px;
  padding-bottom: 50px;
}

.received-image {
  background-color: #fae9f2;
  width: 250px;
  height: 150px;
  margin: auto;
  border-radius: 15px;
}

.parag-2 {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  margin-bottom: 50px;
  margin-left: 20px;
  align-content: center;
  text-align: center;
}

.plan-page {
  margin-top: 100px;
}

.plan {
  background-color: #242526;
  color: white;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  height: 220px;
  margin-top: 30px;
  min-width: 200px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.plan-2 {
  background-color: #242526;
  color: white;
  border-radius: 15px;
  padding: 30px;
  min-width: 200px;
  max-width: 300px;
}

.all-plans {
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 900px) {
  .all-plans {
    flex-direction: column;
    align-items: center;
  }

  .plan-2 {
    margin-top: 30px;
  }

  .plan-page-header {
    margin-bottom: 0px !important;
  }

  .plan-page {
    margin-top: 0px !important;
  }

  .subscription-logo {
    width: 60%;
    margin-left: 57px;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .subscription-logo {
    width: 30%;
    margin-left: 200px;
  }
  .landing-header {
    margin-left: 155px;
  }
  .getstarted-button {
    margin-left: 250px;
  }
}

.plan-header {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
}
.plan-detail {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  text-align: center;
}
.dash {
  display: flex;
  justify-content: center;
  align-items: center;
}
.plan-price-detail {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  text-align: center;
}

.plan-price {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  text-align: center;
}

.plan-button {
  background-color: white;
  color: #242526;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  width: 170px;
}

.price-plan-btn-wrapper {
  display: flex;
  justify-content: center;
}

.plan-page-header {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  margin-bottom: 40px;
  text-align: center;
}

.plan-detail-2 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
}
.plan-header-2 {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px;
}

.price {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.landing-buttons {
  float: right;
  padding: 50px;
}

.video {
  width: 100%;
}
.cardss {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.cardss-flex {
  margin-right: 0px !important;
  margin-left: 0px !important;
  display: flex;
  justify-content: space-around;
}
.cardss-view {
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.getstarted-button {
  background-color: #242526;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 410px;
  margin-top: 40px;
  width: 200px;
  height: 50px;
  border: none;
}

.landing-header {
  font-family: "Poppins", sans-serif;
  margin-bottom: 50px;
  margin-left: 230px;
  color: #242526;
  text-align: center;
  width: 50%;
}

.landing-signup {
  background-color: #242526;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  width: 130px;
  height: 50px;
  border: none;
  margin-right: 15px;
}

.landing-login {
  color: black;
  background-color: white;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  width: 130px;
  height: 50px;
  border: none;
}

.dashboard-button {
  background-color: #242526;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  border-radius: 5px;
  width: 170px;
  height: 40px;
  border: none;
  margin-bottom: 15px;
  margin-right: 15px;
  text-align: center;
}

.dashboard-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.or {
  margin-left: 15px;
  margin-top: 5px;
}
.or p {
  font-size: 18px;
}
.radio {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.radio label {
  font-size: 14px;
}
.constbtn {
  width: 150px;
  font-size: 15px;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.list-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.report-items {
  list-style: none;
  display: flex;
  flex-direction: column;

  align-items: center;
}

.report-item {
  background-color: #fae9f2;
  margin: 20px;
  padding: 15px;
  border-radius: 10px;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.item-icon {
  font-size: 25px;
  margin-right: 5px;
  font-weight: bold;
}
.dashboard-search {
  display: flex;
  justify-content: center;
}

.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tab {
  width: 100%;
  color: #1a252f;
  overflow: hidden;
}
.tab-label-view {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #dae0e5;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}
.tab-label-view:hover {
  background: #a2a7ac;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #dae0e5;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}
.tab-label:hover {
  background: #a2a7ac;
}
.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #1a252f;
  background: white;
  transition: all 0.35s;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #dae0e5;
  cursor: pointer;
}
.tab-close:hover {
  background: #a2a7ac;
}
input:checked + .tab-label {
  background: #a2a7ac;
}
input:checked + .tab-label::after {
  transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
}

@media screen and (min-width: 900px) {
  .dash {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 900px) {
  .landing-header {
    margin-left: 15px;
    width: 90%;
  }
  .getstarted-button {
    margin-left: 70px;
  }
  .dashboard-image {
    margin-top: -120px;
  }
  .dashboard-report {
    margin-top: 5px;
    margin-right: 70px;
  }

  .search-icon {
    float: right;
    margin-top: -25px;
  }
  .dash {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .dashboard-tittle {
    margin-left: 0px;
  }

  .dashboard-buttons {
    background-color: #242526;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    padding-left: 30px;
    padding-right: 30px;
    width: 170px;
    height: 40px;
    border: none;
    margin-left: -20px;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .landing-header {
    margin-left: 30px;
  }
  .getstarted-button {
    margin-left: 250px;
  }

  .search-icon {
    margin-top: 10px;
  }

  .dash {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.separator {
  margin-top: 21px;
  margin-bottom: 20px;
}

.spinner-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view-list {
  min-height: 31px;
}
.back-button-wrapper {
  position: fixed;
  bottom: 12px;
  font-size: 30px;
  padding: 50px;
  font-size: 20px;
}

.btn-adamos {
  background-color: #008dab;
  color:#ffffff;
  font-weight: 600;
  font-family: Saira;
}
