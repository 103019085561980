.machine-card-machine-tool-card {
  flex-direction: column;
  max-height: 160px;
  max-width: 270px;
}

.machine-card-name-machine-tool-card {
  text-align: center;
}

.machine-card-button-machine-tool-card {
  gap: 30px;
  height: 35px;
  position: relative;
}

.toggle-button-machine-tool-card {
  width: 120px;
}

.expanded-menu-button-container-machine-tool-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: calc(65% - 25px);
  top: 100%;
  max-width: 120px;
  padding-bottom: 100px;
  z-index: 1;
}

.expanded-menu-button-container-right-machine-tool-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: calc(65% - 26px);
  top: 100%;
  max-width: 120px;
  padding-bottom: 100px;
  z-index: 1;
}

.expanded-menu-button-top-edge-machine-tool-card {
  border-top: 1px solid #fff;
  border-radius: 5px 5px 0 0;
  box-shadow: 2px 5px 5px 1px #0000005e;
}

.expanded-menu-button-top-edge-machine-tool-card :hover {
  color: black;
  border-top: 1px solid #fff !important;
}

.expanded-menu-button-middle-machine-tool-card {
  border-top: 1px solid #fff;
  border-radius: 0 0 0 0;
  box-shadow: 2px 5px 5px 1px #0000005e;
}

.expanded-menu-button-bottom-edge-machine-tool-card {
  border-top: 1px solid #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 5px 5px 1px #0000005e;
}
