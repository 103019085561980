@import url("https://fonts.googleapis.com/css2?family=Andika:wght@400;700&family=Cabin&family=Josefin+Sans&family=Lobster&family=M+PLUS+Rounded+1c&family=Poppins&family=Space+Grotesk&display=swap");

.essential-optimization-modal-content {
  color: #000 !important;
  min-width: 400px;
  height: 500px;
  padding: 15px 25px !important;
  background: #fff !important;
  border-radius: 18px;
  overflow: auto;
  border-collapse: collapse;
  font-family: "Poppins", sans-serif;
  box-shadow: 0 0 50px rgba(0, 0, 0, 1);
  margin: 0;
}