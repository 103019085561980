.selected {
  background-color: blue;
  color: white;
}

span {
  margin-bottom: 20px;
}

.tooling-input-container {
  display: flex;
  flex-direction: column;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.info-button {
  width: 1.5em;
  top: 0;
  right: 0;
  cursor: pointer;
}

.message-box {
  position: absolute;
  left: 13rem;
  width: 170px;
  background-color: #f2f2f2;
  border-radius: 0.5rem;
  z-index: 1;
}
