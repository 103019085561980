.spindle-health-monitor-container {
  height: 100%;
}

.button {
  margin-top: 8em;
  margin-left: 4em;
  width: 10em;
  height: 3em;
}

.back-to-shop-floor {
  width: auto;
  height: 50px;
  margin-left: -7rem;
}

@media (max-width: 1449px) {
  .back-to-shop-floor {
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
}

.main-container-class {
  margin-top: 10rem;
}

@media (min-width: 1449px) {
  .main-container-class {
    margin-top: 5rem;
  }
}

.chart-div {
  margin-top: 5em;
}

.back-button {
  font-weight: bold;
  max-width: 150px;
}

.row {
  justify-content: center;
}

.active-button {
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.25);
  color: white;
  background-color: #3498db;
  height: 45px;
  width: 65px;
}

.not-active-button {
  box-shadow: inset -5px -5px 10px rgba(0, 0, 0, 0.25),
    inset 5px 5px 10px rgba(255, 255, 255, 0.45);
  color: white;
  background-color: #3498db;
  height: 45px;
  width: 65px;
}

.active:hover,
.not-active:hover {
  color: white;
}
