.card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-bold {
  font-weight: bold;
}

.recharts-wrapper {
  cursor: crosshair !important;
}

.recharts-scatter-symbol {
  cursor: pointer;
}

.recharts-cartesian-axis-tick {
  user-select: none;
}

.plot-container {
  position: relative;
}

.chart-button {
  position: absolute;
  align-items: center;
  bottom: 70px;
  z-index: 1;
}