.card-link {
  position: relative;
  display: block;
}

.pale-image {
  filter: brightness(40%);
}

.coming-soon-text {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(320deg);
  color: red;
  font-weight: bold;
  font-size: 40px;
  z-index: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.notify-modal-content {
  padding: 5px;
  border-radius: 18px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.dashboard-card {
  border-style: solid;
  border-width: 1.5px;
  border-color: #2250fb;
  background-color: white;
  border-radius: 1.5rem;
  width: 18.5rem;
  margin-right: 30px;
  height: 24.5rem;
}

.dashboard-card-active {
  border-style: solid;
  border-width: 1.5px;
  background-color: #2250fb;
  color: white;
  border-radius: 1.5rem;
  width: 18.5rem;
  margin-right: 30px;
  height: 24.5rem;
}

.digibasic-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2250fb;
  border-radius: 1.5rem;
  color: white;
  width: 59.5rem;
  margin-left: 0.5rem !important;
  height: 15rem;
}

.essential-optimization-card {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1.5px solid #2250fb;
  border-radius: 1.5rem;
  width: 59.5rem;
  margin-left: 0.5rem !important;
  height: 12rem;
}

.information-card {
  border-style: solid;
  border-width: 1.5px;
  border-color: #2250fb;
  border-radius: 1.5rem;
  width: 59.2rem;
  height: 14rem;
}

.get-notified-button {
  border-radius: 0.5rem;
  background-color: #2250fb;
  color: white;
  margin-top: 2.8rem;
}

.about-this-promotion-button {
  border-radius: 0.5rem;
  background-color: white;
  color: #2250fb;
}

.explore-tap-testing {
  border-radius: 0.5rem;
  background-color: #2250fb;
  color: white;
}

.card-heading {
  font-size: 26px;
  font-family: "Poppins";
}
