.left-sidebar {
  height: 100vh;
  width: 170px;
}

.selected {
  background-color: blue;
  color: white;
}

.sidebar-options .sidebar-option {
  background-color: blue;
  color: white;
}

.digibasic-wrapper {
  position: "fixed";
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
  background-color: "#000000";
  opacity: 0.5;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  z-index: "9999";
}

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  color: #3498db;
  width: 3rem;
  height: 3rem;
}

.refresh-session-button {
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  height: 40px;
  color: white;
  background-color: #fdba45;
  box-shadow: inset -5px -5px 10px rgba(0, 0, 0, 0.25),
    inset 5px 5px 10px rgba(255, 255, 255, 0.45);
  border-radius: 4px;
}

.nav-buttons {
  background-color: #3498db;
  color: white;
  display: block;
  width: 150px;
  height: 130px;
  align-content: flex-start;
}

.nav-buttons:hover {
  color: white;
}

@media screen and (max-width: 994px) {
  .nav-buttons {
    width: 120px;
    height: 100px;
  }
}

.nav-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
