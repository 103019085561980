.selected {
  background-color: blue;
  color: white;
}

span {
  margin-bottom: 20px;
}

.tooling-input-container {
  display: flex;
  flex-direction: column;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
