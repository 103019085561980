.modal-container-add-new-machine {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px); /* This will make the background blur */
  z-index: 1; /* Make sure the modal is on top of other elements */
}
  
  .main-container-pop-add-new-machine {
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 3px 3px 3px 3px rgb(0 0 0 / 10%);
    width: auto;
    height: auto;
  }

  .pop-up-content-add-new-machine {
    width: 100%;
  }
  
  .input-wrapper-add-new-machine {
    padding: 5px;
    gap: 10px;
  }
  
  .input-wrapper-add-new-machine input {
    width: 280px;
  }
  .form-control-size-add-new-machine{
    width: 280px;
  }

  .title-add-new-machine {
    font-weight: 500;
    font-size: 32px;
  }
  
  .create-btn-add-new-machine {
    display: block;
    width: 100px;
    font-size: 16px;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
  }
  
  .wrapper-add-new-machine {
    display: flex;
  }
  
  .exit-btn {
    border-radius: 50%;
    border: none;
    font-weight: 700;
    font-size: 20px;
    background-color: transparent;
  }
  .btn-close {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 26px;
  }
  
  .fs-xl {
    font-size: x-large;
  }