.container-delete-machine {
    position: fixed;
    z-index: 9999; /* Adjust the z-index to be higher than other components */
    top: 40%;
    left: 40%;
    width: 350px;
    padding: 30px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 5%);
    justify-content: center;
    align-items: center;
  }
  .title-delete-machine {
    display: flex;
    justify-content: center;
    font-weight: 600;
  }

  .button-delete-machine {
    width: 120px;
    height: 35px;
    border-radius: 3px;
  }